import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import i18n from "../../i18n/i18n";
import { GetRequest } from "../../utils/ApiServices";

function CourseCategories({ view = "headerMenu", closeMenu }) {
  const [categories, setCategories] = useState([]);

  const fetchData = async () => {
    const res = await GetRequest("course-categories1");
    if (res && res?.categories) {
      setCategories([...res.categories]);
    }
  };

  useEffect(() => {
    if (categories.length === 0) {
      fetchData();
    }
  }, []);

  const renderCompnent = () => {
    switch (view) {
      case "headerMenu":
        return (
          <ul className="sub-menu">
            {categories.map((el, i) => (
              <li key={i}>
                <Link to={`category/${el?.slug ?? el?.id}`}>{el.title}</Link>
              </li>
            ))}
          </ul>
        );

      case "MobileMenu":
        return (
          <Accordion style={{ width: "100%" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span style={{ fontWeight: "500", color: "black" }}>
                  {i18n.t("HeaderCategories")}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ul className="sub-menu">
                  {categories.map((el, i) => (
                    <li key={i}>
                      <Link
                        onClick={closeMenu}
                        to={`category/${el?.slug ?? el?.id}`}
                      >
                        {el.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      default:
        break;
    }
  };

  return renderCompnent();
}

export default CourseCategories;
