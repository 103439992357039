import React from "react";
import SingleSection from "./SingleSection";
import { useState, useEffect } from "react";
import { GetRequest } from "../../../utils/ApiServices";

function Sections() {
  const [sections, setSections] = useState([]);

  const getSections = async () => {
    const res = await GetRequest("sections");
    if (res && res?.status === true) {
      setSections([...res?.sections]);
    }
  };

  useEffect(() => {
    getSections();
  }, []);

  return (
    <>
      {sections.map((el, i) => (
        <SingleSection key={i} section={el} />
      ))}
    </>
  );
}

export default Sections;
