import { addError } from "./errorActions";

import {
  ADD_COURSE,
  EDIT_COURSE,
  DELETE_COURSE,
  SET_COURSES,
} from "../constants/courseConstants";
import { GetRequest, PostRequest } from "../../utils/ApiServices";

const removeCourse = (id) => async (dispatch) => {
  dispatch({ type: DELETE_COURSE, payload: id });
};

const addCourse = (course) => async (dispatch) => {
  dispatch({ type: ADD_COURSE, payload: course });
};

const editCourse = (course) => async (dispatch) => {
  dispatch({ type: EDIT_COURSE, payload: course });
};

export const listCourses = async (dispatch) => {
  try {
    const res = await GetRequest("courses1");
    //  console.log(data)

    dispatch({
      type: "SET_COURSES",
      payload: res,
    });
  } catch (error) {
    // console.log(error.message)
    addError(dispatch, error.message);
  }
};

export const listCategoryCourses = async (id, dispatch) => {
  try {
    const res = await GetRequest(`courses/category1/${id}`);
    //  console.log(data)
    dispatch({
      type: "SET_COURSES",
      payload: res,
    });

    return {};
  } catch (error) {
    // console.log(error.message)
    addError(dispatch, error.message);
  }
};

export const listSolutionCourses = async (id, dispatch) => {
  const res = await GetRequest(`courses/${id}`);
  if (res) {
    dispatch({
      type: "SET_COURSES",
      payload: res,
    });
  }
  return {};
};

export const apiApplySearchFilter = async (search, dispatch) => {
  try {
    const res = await PostRequest(`search1?keyword=${search}`);
    return res;
  } catch (error) {
    // console.log(error.message)
    addError(dispatch, error.message);
  }
};

export const apiGetRelatedCourses = async (courseId) => {
  return await GetRequest(`other_courses1/${courseId}`);
};

export const apiGetSpecialOffers = async () => {
  return await GetRequest(`get-special-offer-courses1`);
};

export const apiGetUpcomingEvents = async () => {
  return await GetRequest(`get-upcoming-events1`);
};
