import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./app.css";
import { ErrorDisplay } from "../ErrorDisplay/ErrorDisplay";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { Helmet } from "react-helmet";
import { PostRequest } from "../../utils/ApiServices";

export function ForgotPassword() {
  const navigate = useNavigate();
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { value } = useParams();
  const parts = value.split("=");
  const encodedToken = parts[1];
  const [id, setID] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const fetchdata = async () => {
    const res = await PostRequest("check-password-email", {
      token: encodedToken,
    });
    if (res) {
      setID(res?.id);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (value) {
      fetchdata();
    }
  }, [value]);

  const onPwdChange = (e) => {
    setNewPwd(e.target.value);
  };

  const onConifrmChange = (e) => {
    setConfirmPwd(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPwd == "" || confirmPwd == "") {
      setLoading(true);
      setMessage("Please fill details");
    } else if (newPwd !== confirmPwd) {
      setLoading(true);
      setMessage("Password Do not Match");
    } else {
      setShowSpinner(true);
      const res = await PostRequest("change-password", {
        id: id,
        password: newPwd,
      });
      if (res) {
        toast.success("Password Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        navigate("/");
      }
    }
    setShowSpinner(false);
    setLoading(false);
  };

  return (
    <div className="main-container">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      {showSpinner && <LoadingSpinner />}
      <div className="forget-section">
        <form className="form" onSubmit={handleSubmit}>
          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            {loading && <ErrorDisplay message={message} />}
          </div>

          <h2>Enter Password</h2>
          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            <input
              type="password"
              name="password"
              placeholder="New password"
              onChange={onPwdChange}
            />
          </div>

          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            <input
              type="password"
              name="confirmpassword"
              placeholder="Confirm password"
              onChange={onConifrmChange}
            />
          </div>

          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            <button className="btn btn-primary btn-hover-dark ">
              Confirm <i className="flaticon-right" onChange={setConfirmPwd} />
            </button>
          </div>

          <div
            className="single-form col-xs-12 col-sm-12 col-md-12 "
            id="login-link"
          >
            or{" "}
            <Link to="/login">
              <span>Login</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
