import React, { useState, useEffect, useContext } from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import { GlobalContext } from "../../context/GlobalState";
import { useCookies } from "react-cookie";
import { Modal } from "../CartModal/CartModal";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import {
  GetRequest,
  HandleMetaTags,
  PostRequest,
} from "../../utils/ApiServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./form.css";
import { toast } from "react-toastify";

const DynamicForm = () => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const form_id = searchParams.get("id");
  const navigate = useNavigate();

  // const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [myJwtCookie, setMyJwtCookie] = useCookies(["jwt"]);

  const [showSpinner, setSpinner] = useState(false);
  const { userState } = useContext(GlobalContext);
  const user = userState.user;
  const [Pagination, setPagination] = useState(null);

  const fetchdata = async () => {
    HandleMetaTags(1, 157);
    setLoading(true);
    const res = await GetRequest(`questionnire-details/${form_id}`);
    if (res) {
      if (res?.pagination === true) {
        setPagination(0);
      }
      setFormData(res);
    } else {
      navigate("/");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!myJwtCookie?.jwt) {
      toast.error("Please sign in to access the form.");
      localStorage.setItem("login-redirect", `/form?id=${form_id}`);
      navigate("/login");
    } else {
      if (form_id) {
        fetchdata();
      } else {
        navigate("/");
      }
    }

    // setCookie("user", user);
  }, [user]);

  const handleChange = (questionId, value) => {
    setFormValues((prev) => ({ ...prev, [questionId]: value }));
  };
  const handleSelectChange = (questionId, value, checked) => {
    let currentList = formValues?.[questionId] ?? [];
    if (checked) {
      currentList = [...currentList, value];
    } else {
      currentList = currentList.filter((it) => it !== value);
    }
    setFormValues((prev) => ({ ...prev, [questionId]: currentList }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      toast.success("Please sign in to access the form.");
      navigate("/login");
    }
    if (Pagination !== null) {
      if (formData?.questions?.length > Pagination + 1) {
        if (formData?.questions[Pagination]?.required) {
          if (formValues?.[formData?.questions[Pagination]?.question_id]) {
            setPagination((prev) => prev + 1);
          } else {
            toast.error("Please answer the question to proceed.");
          }
        } else {
          setPagination((prev) => prev + 1);
        }
        return;
        // setPagination((prev) => prev + 1);
        // return;
      } else if (
        formData?.questions?.length === Pagination + 1 &&
        !formValues?.[formData?.questions[Pagination]?.question_id]
      ) {
        toast.error("Please answer the question to proceed.");
        return;
      }
    }
    setLoading(true);
    const res = await PostRequest("submit-analysis-questionnaire", {
      questionnaire_id: formData?.questionnaire_id ?? "No id presented",
      answers: formValues,
      customer_id: user?.id,
    });
    if (res) {
      toast.success("Your answers have been successfully submitted.");
      navigate("/form-success");
    }
    setLoading(false);
  };

  // const handleCloseModal = () => {
  //   setIsOpen(false);
  //   setSpinner(false);
  //   //console.log(isOpen)
  // };

  if (loading) {
    return (
      <div>
        <div className="section page-banner-noshapes"></div>
        <div className="container">
          <div className="courses-wrapper-02" style={{ width: "100%" }}>
            <div className="row">
              <center>
                <Spinner animation="border" variant="primary" />
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {showSpinner && <LoadingSpinner />}

      <div className="section page-banner-noshapes"></div>
      <div className="w-3/4 flex flex-col items-center p-4 p-md-10">
        <h1 className="text-2xl font-bold mb-6 text-center">
          {formData?.title ?? "Dynamic Form"}
        </h1>
        {Pagination !== null && (
          <ProgressBar
            now={
              formData?.questions?.length
                ? ((Pagination + 1) / formData.questions.length) * 100
                : 0
            }
            label={
              formData?.questions?.length
                ? `${Pagination + 1}/${formData?.questions?.length}`
                : ""
            }
            style={{ height: "15px", marginBottom: "30px" }}
          />
        )}

        <form onSubmit={handleSubmit} className="w-full max-w-3xl space-y-5">
          <div className="w-full row">
            {formData?.questions.map((q, index) => {
              if (
                Pagination === null ||
                (Pagination !== null && index === Pagination)
              )
                return (
                  <div
                    key={q.question_id}
                    className={`bg-white shadow-md rounded-lg px-4 py-2 ${
                      Pagination === null ? "col-md-6 col-12" : "col-12"
                    } `}
                  >
                    <label className="block text-lg font-medium text-gray-700 mb-1">
                      {q.question_text}
                    </label>

                    {q.question_type === "text" && (
                      <input
                        type="text"
                        className=""
                        onChange={(e) =>
                          handleChange(q.question_id, e.target.value)
                        }
                        placeholder="Enter your response..."
                      />
                    )}

                    {q.question_type === "checkbox" && (
                      <div className="w-100 overflow-auto border border-secondary rounded p-2">
                        {q.question_type === "checkbox" && (
                          <div className="w-full">
                            {q.options.map((opt) => (
                              <div
                                key={opt.option_id}
                                className="d-flex align-items-center mb-2 p-2 border border-gray-300 rounded"
                              >
                                <div className="me-2">
                                  <input
                                    type="checkbox"
                                    id={opt.option_id}
                                    value={opt.option_text}
                                    className="bg-secondary "
                                    style={{ height: "18px" }}
                                    onChange={(e) => {
                                      handleSelectChange(
                                        q.question_id,
                                        opt.option_text,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </div>
                                <label
                                  htmlFor={opt.option_id}
                                  className="text-sm text-muted overflow-auto"
                                >
                                  {opt.option_text}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {q.question_type === "radio" && (
                      <div className="w-100 overflow-auto border border-secondary rounded p-2">
                        <div className="w-full">
                          {q.options.map((opt) => (
                            <div
                              key={opt.option_id}
                              className="d-flex align-items-center mb-2 p-2 border border-gray-300 rounded"
                            >
                              <div className="me-2">
                                <input
                                  type="radio"
                                  id={opt.option_id}
                                  value={opt.option_text}
                                  checked={
                                    formValues?.[q.question_id] ===
                                    opt.option_text
                                  }
                                  className="bg-secondary "
                                  style={{ height: "18px" }}
                                  onChange={(e) => {
                                    handleChange(
                                      q.question_id,
                                      opt.option_text
                                    );
                                  }}
                                />
                              </div>
                              <label
                                htmlFor={opt.option_id}
                                className="text-sm text-muted overflow-auto"
                              >
                                {opt.option_text}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {q.question_type === "select" && (
                      // <select
                      //   className="w-full h-12 border border-gray-300 rounded-lg px-3 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      //   onChange={(e) =>
                      //     handleChange(q.question_id, e.target.value)
                      //   }
                      // >
                      //   <option value="">Select an option</option>
                      //   {q.options.map((opt) => (
                      //     <option key={opt.option_id} value={opt.option_text}>
                      //       {opt.option_text}
                      //     </option>
                      //   ))}
                      // </select>

                      <select
                        className="custom-select"
                        onChange={(e) =>
                          handleChange(q.question_id, e.target.value)
                        }
                      >
                        <option value="">Select an option</option>
                        {q.options.map((opt) => (
                          <option key={opt.option_id} value={opt.option_text}>
                            {opt.option_text}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                );
              else {
                return null;
              }
            })}
          </div>

          <div className="d-flex justify-content-center mt-4 mb-4">
            {Pagination > 0 && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  setPagination((prev) => prev - 1);
                }}
                className="btn btn-primary text-white me-2"
              >
                Back
              </button>
            )}

            <button type="submit" className="btn btn-primary text-white">
              {Pagination !== null &&
              formData?.questions?.length > Pagination + 1
                ? "Next"
                : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DynamicForm;
