import { React, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./app.css";
import { ErrorDisplay } from "../ErrorDisplay/ErrorDisplay";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { GlobalContext } from "../../context/GlobalState";
import {
  addUserWithJwt,
  removeUserWithJwt,
} from "../../context/actions/userActions/userActions";
import { PostRequest } from "../../utils/ApiServices";

export function EmailUser() {
  const { userState, userDispatch, singleCartState, singlecartDispatch } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { value } = useParams();
  const parts = value.split("=");
  const encodedToken = parts[1];
  const [id, setID] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 7);

  const fetchData = async () => {
    setShowSpinner(true);
    localStorage.setItem("jwtemail", JSON.stringify(encodedToken));
    const res = await PostRequest("check-email-password", {
      token: encodedToken,
    });
    if (res && res?.id) {
      setID(res.data.id);
    } else {
      localStorage.setItem("jwtemail", JSON.stringify([]));
      removeUserWithJwt(userDispatch);
      navigate("/");
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    if (value) {
      fetchData();
    }
  }, [value]);

  const onPwdChange = (e) => {
    setNewPwd(e.target.value);
  };

  const onConifrmChange = (e) => {
    setConfirmPwd(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPwd == "" || confirmPwd == "") {
      setLoading(true);
      setMessage("please fill details");
    } else if (newPwd !== confirmPwd) {
      setLoading(true);
      setMessage("Password Do not Match");
    } else {
      setShowSpinner(true);
      const res = await PostRequest("change-password", {
        id: id,
        password: newPwd,
      });
      if (res) {
        toast.success("Password Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        navigate("/");
      }
      setShowSpinner(false);
      setLoading(false);
    }
  };

  return (
    <div className="main-container">
      {showSpinner && <LoadingSpinner />}
      <div className="forget-section">
        <form className="form" onSubmit={handleSubmit}>
          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            {loading && <ErrorDisplay message={message} />}
          </div>
          <h2>Enter Password</h2>
          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            <input
              type="password"
              name="password"
              placeholder="New password"
              onChange={onPwdChange}
            />
          </div>

          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            <input
              type="password"
              name="confirmpassword"
              placeholder="Confirm password"
              onChange={onConifrmChange}
            />
          </div>

          <div className="single-form col-xs-12 col-sm-12 col-md-12">
            <button className="btn btn-primary btn-hover-dark ">
              Confirm <i className="flaticon-right" onChange={setConfirmPwd} />
            </button>
          </div>

          <div
            className="single-form col-xs-12 col-sm-12 col-md-12 "
            id="login-link"
          >
            or{" "}
            <Link to="/login">
              <span>Login</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
