import React from "react";
import { Link } from "react-router-dom";
import "./scrolbar.css";

function SectionCourses({ deals = [] }) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#e7f8ee",
        borderRadius: "10px",
      }}
      className="mt-5 pb-10"
    >
      {deals.map((deal, i) => (
        <div
          key={i}
          className=""
          style={{
            padding: "10px 30px",
            position: "relative",
          }}
        >
          <h3 className="mt-3">Special Offers</h3>
          <div className="overflow-auto">
            <div className="row g-3 flex-nowrap">
              {deal?.course_list.map((el, i) => (
                <div key={i} className="col-12 col-md-6 col-lg-3">
                  <Link to={`/course/${el?.slug ?? el?.id}`}>
                    <div className="card rounded h-90">
                      <div
                        className="card-img-top"
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}uploads/course_items/${el.featuredImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <div className="card-body d-flex flex-column text-center px-4 justify-content-between">
                        <h6
                          className="card-title text-truncate"
                          style={{
                            fontSize: "1.3rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          title={el.title} // Tooltip for truncated text
                        >
                          {el.title}
                        </h6>
                        {el?.price ? (
                          <span
                            style={{
                              fontSize: "1.2rem",
                              fontWeight: "x-large",
                            }}
                          >
                            AED {el.price}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "1.2rem",
                              fontWeight: "x-large",
                            }}
                          >
                            FREE
                          </span>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SectionCourses;
