import { PostRequest } from "../../utils/ApiServices";

export const sendContact = async (contact) => {
  return await PostRequest("save-detail", {
    contact_no: contact.contact_no,
    name: contact.name,
    email: contact.email,
    message: contact.message,
    subject: contact.subject,
  });
};
