import axios from "axios";

import i18n from "../i18n/i18n";

const axiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL + `${i18n.language ?? "en"}`,
  timeout: 100000,
  timeoutErrorMessage: "Server is not reachable. Please try later.",
});

axiosInterceptor.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_API_URL + `${i18n.language ?? "en"}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (response) => response, // Return response as is
  (error) => {
    // console.error("API Error:", error);
    return Promise.reject(error);
  }
);

// const axiosInterceptor = {
//   initialise: () => {
//     axios.defaults.timeout = 100000;
//     axios.defaults.timeoutErrorMessage =
//       "Server is not reachable. Please try later.";

//     // Request Interceptor. All Request pass from here
//     axios.interceptors.request.use(async (axiosConfig) => {
//       axiosConfig.baseURL =
//         process.env.REACT_APP_API_URL + `${i18n.language ?? "en"}`;

//       return axiosConfig;
//     });
//   },
// };

export default axiosInterceptor;
