import React from 'react'

function SliderShape() {
  return (
    <>
      <div className="slider-shape">
        <img className="shape-1 animation-round" src="images/shape/shape-8.png" alt="Shape" />
      </div> 
    </>
  )
}


export default SliderShape
