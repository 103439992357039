import { toast } from "react-toastify";
import axiosInterceptor from "./axiosInterceptor";

export const Headers = {
  mutipart: {
    "Content-Type": "multipart/form-data",
  },
};

export const PostRequest = async (Api, Body, Head = {}) => {
  try {
    const res = await axiosInterceptor.post(Api, Body, { headers: Head });
    return res?.data; // Now returning the response correctly
  } catch (error) {
    handleError(error);
    return null;
  }
};

export const GetRequest = async (Api, params = {}) => {
  try {
    const res = await axiosInterceptor.get(Api, { params });
    return res?.data; // Returning response
  } catch (error) {
    handleError(error);
    return null;
  }
};

const handleError = (error) => {
  console.log(error);
  if (error?.response?.data?.message) {
    toast.error(error?.response?.data?.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  }
};

export const HandleMetaTags = async (type, id) => {
  const metaTags = await GetRequest(`geta-meta-tags1/${type}/${id}`);
  if (metaTags?.tag && metaTags?.description) {
    window.updateMetaTags(metaTags.tag, metaTags.description);
  }
};
