import "./modal.css";
import { GoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { PostRequest } from "../../utils/ApiServices";

export const Modal = ({
  setMyJwtCookie,
  setSpinner,
  handleCloseModal,
  isOpen,
  setIsOpen,
}) => {
  const sendDataToServer = async (token, setIsOpen) => {
    const res = PostRequest("login/google", { id_token: token });
    if (res) {
      setMyJwtCookie("jwt", res?.jwt_token);
    }
    setIsOpen(false);
    setSpinner(false);
  };

  const BackDrop = () => {
    return <div className="backdrop"></div>;
  };

  return (
    <div>
      {isOpen && <BackDrop />}
      {isOpen && (
        <div className="modal bd-example-modal-sm">
          <div class="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseModal}>
                X
              </button>
              <p>Please register or login </p>
              <div class="d-flex flex-column text-center">
                <Link
                  className="btn btn-info btn-block btn-round login-btn"
                  to="/login"
                >
                  Sign In
                </Link>
              </div>
              <br />
              <div class="text-center text-muted delimiter">
                or use a social network
              </div>
              <br />
              <GoogleLogin
                className="google-button"
                onSuccess={(credentialResponse) => {
                  sendDataToServer(credentialResponse.credential, setIsOpen);
                  setIsOpen(false);
                }}
                onError={() => {
                  console.log("Login Failed");
                  setIsOpen(false);
                }}
              />
              <hr />
              <div className="account_create">
                Not a member yet?
                <Link to={"/"}> Sign Up</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
