import { toast } from "react-toastify";
import { PostRequest } from "../../utils/ApiServices";

export const sendInstructorDetails = async (formData) => {
  if (formData["courseCategory"] == "other") {
    formData["courseCategory"] = `other_${formData["newCourseCategory"]}`;
  }
  delete formData["newCourseCategory"];
  if (validateFormData(formData)) {
    await PostRequest("save-instructor-detail", formData);
    toast.success(
      "Details saved successfully. We will be in touch with you shortly.!",
      { position: toast.POSITION.TOP_CENTER, autoClose: 3000 }
    );
    return { status: 200 };
  }
};
const validateFormData = (data) => {
  for (const [key, value] of Object.entries(data)) {
    if (value === "" || value === null || value === undefined) {
      console.log(key);
      toast.error("Please fill all the fields", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
  }
  return true;
};
