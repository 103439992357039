import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LoadingElement } from "../LoadingElement/LoadingElement";
import { VimeoPlayer } from "../ReactPlayer/VimeoPlayer";
import CollapsePreview from "./CollapsePreview";
import Accordion from "react-bootstrap/Accordion";
import CoursePreviewModal from "./CoursePreviewModal";
import { GetRequest } from "../../utils/ApiServices";

function TrainingDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [courseRaw, setCourseRaw] = useState(null);
  const handleVideoReady = () => {
    alert("video is ready");
    setVideoLoaded(true);
  };

  const _handleClickPreview = (videoUrl) => {
    setShowPreviewModal(videoUrl);
  };
  const getCourseDetails = async (id) => {
    const tempCourse = {
      details: null,
      sections: [],
    };
    try {
      const responseDetails = await GetRequest(`solution-details/${id}`);
      if (responseDetails) {
        tempCourse.details = responseDetails;
        tempCourse.id = responseDetails?.details?.id;
        const responseSections = await GetRequest(
          `all-course-section1/${tempCourse.details?.id}`
        );
        if (responseSections) {
          tempCourse.sections = responseSections;
        }
        setCourseRaw(tempCourse);
        //update meta tags
        if (window.updateMetaTags) {
          window.updateMetaTags(
            tempCourse.details.meta_title,
            tempCourse.details.meta_description
          );
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log("Something Went Wrong", error);
    }
  };

  useEffect(() => {
    getCourseDetails(id);

    return () => {
      setCourseRaw(null);
    };
  }, [id]);

  function SingleCourseComponent({ course }) {
    const { video_url, title, btn_name, btn_link, category_name } =
      course.details;
    return (
      <>
        <div
          className="bg-secondary d-flex flex-column justify-content-end"
          style={{ height: "12rem" }}
        >
          <div className="p-3 px-5">
            <div className="page-banner-content">
              <ul className="breadcrumb ps-2">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li className="active">{category_name ?? "Training"}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* course details section */}
        <div
          className="d-flex justify-content-center px-5 pb-2"
          style={{ width: "100%" }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12">
              <div className="courses-details">
                <h1 className="title row justify-content-between">
                  <span className="col-lg-8"> {title}</span>
                  <a
                    id="btn-webinar-register"
                    href={btn_link}
                    className="btn btn-primary btn-hover-dark col-lg-2 mt-4 mt-md-0"
                    // onClick={toggleShowWebinarModal}
                  >
                    {btn_name}
                  </a>
                </h1>
                {video_url &&
                  (video_url?.includes("youtube") ||
                    video_url.includes("vimeo")) && (
                    <>
                      <div
                        className="courses-details-images"
                        style={{ paddingBottom: "56%" }}
                      >
                        <div className="video-wrapper">
                          {/* {!videoLoaded && <div className="loader">Loading video...</div>} */}
                          {video_url.includes("youtube") ? (
                            <VimeoPlayer
                              url={video_url}
                              onReady={handleVideoReady}
                            />
                          ) : (
                            <VimeoPlayer
                              url={video_url}
                              onReady={handleVideoReady}
                            />
                          )}
                        </div>
                      </div>
                      <h2 className="title">{title}</h2>
                    </>
                  )}

                {/* <div className="d-flex justify-content-center pt-4 pb-4">
                    <div className="info-btn">
                      <a
                        id="btn-webinar-register"
                        href={btn_link}
                        className="btn btn-primary btn-hover-dark"
                        onClick={toggleShowWebinarModal}
                      >
                        {btn_name}
                      </a>
                    </div>
                  </div> */}

                <div className="courses-details-tab">
                  <div className="details-tab-content">
                    <div
                      className="tab-content"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <div className={"tab-pane fade active"} id="description">
                        <div className="tab-description">
                          <Accordion defaultActiveKey="0">
                            {course?.sections &&
                              course?.sections.map((section, i) => {
                                return (
                                  <Accordion.Item eventKey={`${i}`}>
                                    <Accordion.Header>
                                      <h5>{section.section_title}</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div>
                                        {section?.connect_module === 1 && (
                                          <CollapsePreview
                                            preview={section}
                                            _handleClickPreview={
                                              _handleClickPreview
                                            }
                                          />
                                        )}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              section.course_section_description,
                                          }}
                                        ></div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      {courseRaw !== undefined && courseRaw !== null ? (
        <>
          <SingleCourseComponent course={{ ...courseRaw }} />
          {showPreviewModal && (
            <CoursePreviewModal
              videoUrl={showPreviewModal}
              _handleClickPreview={_handleClickPreview}
            />
          )}
        </>
      ) : (
        <LoadingElement />
      )}
    </div>
  );
}

export default TrainingDetail;
