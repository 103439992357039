
export const coursesInitialState = {
    courses: []
  };
  
  
  export const errorInitialState = {
    error: null
  };

  export const singleCourseInitialState = {
    course: null
  };
  
  export const cartInitialState = {
    cart: [],
  };

  export const authInitialState = {
    token: localStorage.getItem('token') || null,
  };

  // Define the initial state of the user
export const initialUserState = {
  user:  null
};





  

 