import { Link } from "react-router-dom";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import i18n from "../../i18n/i18n";

const CourseListItems = ({ course, solutionItem = false }) => {
  if (!course) {
    console.log("isse");
  }
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const { status } = course;
  const NoCourseRedirection = ["completed", "coming soon"];
  const PrimaryUrl = NoCourseRedirection.includes(status)
    ? "#"
    : `/${solutionItem ? "training" : "course"}/${course.slug ?? course.id}`;

  return (
    <div className="col-lg-3 col-md-6 d-flex">
      {/* Single Courses Start */}
      <div className="single-courses w-100">
        <div className="courses-images">
          {isLoading && <Spinner animation="border" variant="primary" />}
          <Link to={PrimaryUrl}>
            <img
              src={
                process.env.REACT_APP_IMAGE_URL +
                "uploads/course_items/" +
                course.course_image
              }
              alt={`${course.course_name}`}
              onLoad={handleImageLoad}
              style={{ visibility: isLoading ? "none" : "visible" }}
            />
          </Link>
        </div>
        <div className="courses-content">
          {!solutionItem && (
            <div className="courses-author">
              <div className="author">
                <div className="author-thumb">
                  {isLoading && <Spinner animation="border" />}
                  {course.instructor_image && (
                    <Link to={PrimaryUrl}>
                      <img
                        src={
                          course.instructor_image !== "" &&
                          process.env.REACT_APP_IMAGE_URL +
                            "/uploads/instructor_images/" +
                            course.instructor_image
                        }
                        alt={`${course.instructor_name}`}
                        onLoad={handleImageLoad}
                        style={{ display: isLoading ? "none" : "block" }}
                      />
                    </Link>
                  )}
                </div>
                <div className="author-name">
                  <Link className="name" to={PrimaryUrl}>
                    {course.instructor_name}
                  </Link>
                </div>
              </div>
            </div>
          )}
          {!solutionItem && (
            <div className="tag-container">
              {course?.tags?.map((tg) => (
                <div className="tag me-1">
                  <Link to={PrimaryUrl}>{tg}</Link>
                </div>
              ))}
            </div>
          )}
          <h4 className={"title"} style={{ marginTop: "-2px" }}>
            <Link to={PrimaryUrl}>{course.course_name}</Link>
          </h4>
          {course.bln_limitted_offer === 1 && (
            <span className="bold text-danger" style={{ fontSize: "13px" }}>
              Limited Seats
            </span>
          )}
          {!solutionItem && (
            <div className="courses-meta" style={{ marginTop: "-8px" }}>
              <span>
                {" "}
                <i className="icofont-clock-time" /> {course.duration}
              </span>
              <span>
                {" "}
                <i className="icofont-read-book" /> {course.no_lectures}{" "}
              </span>
            </div>
          )}
          {!solutionItem &&
            (status === "coming soon" || status !== "active" ? (
              <Link
                className="courses-price-review d-block bg-primary text-white text-center font-weight-bold"
                style={{ fontWeight: "bold" }}
                to={PrimaryUrl}
              >
                {status === "contact us"
                  ? "Contact Us"
                  : status === "completed"
                  ? "Event Has Ended"
                  : i18n.t("CommingSoon")}
              </Link>
            ) : (
              <div className="courses-price-review bg-primary text-white font-weight-bold">
                <div className="courses-price" style={{ fontWeight: "bold" }}>
                  {course.bln_request_quote === 1 ? (
                    "Request Quote"
                  ) : course.price === 0 ||
                    course.price === "" ||
                    course.price === undefined ||
                    course.price === null ? (
                    "Free"
                  ) : (
                    <span className="sale-price" style={{ fontWeight: "bold" }}>
                      {course?.previous_price && (
                        <span className="old-parice">
                          AED {course?.previous_price}
                        </span>
                      )}
                      {i18n.t("AED")} {course.price}
                    </span>
                  )}
                </div>
                <div className="courses-review">
                  <Link to={PrimaryUrl}>Read More</Link>
                </div>
                {/*<div className="courses-review">
                     <span className="rating-count">4.9</span>
                     <span className="rating-star">
                       <span className="rating-bar" style={{width: '80%'}} />
                     </span>
                   </div>*/}
              </div>
            ))}
        </div>
      </div>
      {/* Single Courses End */}
    </div>
  );
};

export default CourseListItems;
