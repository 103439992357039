import React from "react";
import HotDeals from "./HotDeals";
import FeaturedDeals from "./FeaturedDeals";
import SectionCourses from "./SectionCourses";

function SingleSection({ section }) {
  // console.log(section);
  let CustomCategory = section?.categorical_deals ?? [];
  if (CustomCategory?.length > 0) {
    CustomCategory[0].course_list = [
      ...CustomCategory[0].course_list,
      ...section?.special_offers,
    ];
  }
  return (
    <div>
      {/* <div className="section pt-5">
        <div className="container" style={{ width: "100%" }}>
          <h3 className="pb-2">{section?.title}</h3>
          <HotDeals deals={section?.hot_deals} />
        </div>
      </div> */}
      <div className="section">
        <div className="container" style={{ width: "100%" }}>
          <FeaturedDeals deals={section?.featured_deals} />
        </div>
      </div>

      <div className="section ">
        <div className="container " style={{ width: "100%" }}>
          {/* <SectionCourses deals={[...section.categorical_deals]} /> */}
          <SectionCourses deals={CustomCategory} />
        </div>
      </div>
    </div>
  );
}

export default SingleSection;
