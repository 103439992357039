import { PostRequest } from "../../../utils/ApiServices";

const ADD_USER = "ADD_USER";
const REMOVE_USER = "REMOVE_USER";
const UPDATE_USER = "UPDATE_USER";
const DELETE_USER = "DELETE_USER";
const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const addUser = (user, dispatch) => {
  return {
    type: ADD_USER,
    payload: user,
  };
};

export const removeUser = () => {
  return {
    type: REMOVE_USER,
    payload: null,
  };
};

export const updateUser = (user, dispatch) => {
  return {
    type: UPDATE_USER,
    payload: user,
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER,
  };
};

export const updateLanguage = (language) => {
  return {
    type: UPDATE_LANGUAGE,
    payload: { language },
  };
};
// Define async action creator for adding a user with JWT authentication
export const addUserWithJwt = async (token, dispatch) => {
  try {
    if (token.length < 1) {
      return;
    }
    const res = await PostRequest("loginjwt", { token });
    if (res && res?.user) {
      dispatch(addUser(res?.user));
    }
  } catch (error) {
    // console.error(error);
  }
};

export const removeUserWithJwt = async (dispatch) => {
  dispatch(removeUser());
};

export const paymentDetailAction = async (course, user, paymentIntent) => {
  return await PostRequest("payment-detail", {
    amount: paymentIntent.amount,
    currency: paymentIntent.currency,
    client_secret: paymentIntent.client_secret,
    payment_id: paymentIntent.id,
    course_id: course.id,
    course_name: course.course_name,
    status: paymentIntent.status,
    user_id: user.id,
  });
};

export const addToCartItem = async (cart, user, paymentIntent) => {
  //console.log(cart)
  return await PostRequest("cart-save", {
    course_name: cart.course_name,
    course_language: cart.course_language,
    course_level: cart.course_level,
    course_duration: cart.course_duration,
    no_lectures: cart.no_lectures,
    description: cart.description,
    course_image: cart.course_image,
    price: cart.price,
    course_previous_price: cart.course_previous_price,
    certificate: cart.certificate,
    instructor_name: cart.instructor_name,
    instructor_image: cart.instructor_image,
    curriculum: cart.curriculum,
    certification: cart.certification,
    video_url: cart.video_url,
    user_id: user.id,
    payment_id: paymentIntent.id,
    status: "paid",
    course_id: cart.id,
  });
};

export const updateCartItem = async (course, user, paymentIntent) => {
  return await PostRequest("cart-update", {
    course_id: course.id,
    status: "paid",
    payment_id: paymentIntent.id,
    user_id: user.id,
  });
};
