import { PostRequest } from "../../../utils/ApiServices";

export async function apiRegisterWebniar(postData) {
  const { firstName, lastName, phoneNumber, email, webinarId } = postData;

  console.log(postData);
  const webinarRegistration = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    mobile: phoneNumber,
    course_id: webinarId,
  };

  return await PostRequest("webinar/registration", webinarRegistration);
}
