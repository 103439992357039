import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "./form.css";
import { useNavigate } from "react-router-dom";

const FormSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 8000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer
  }, [navigate]);

  return (
    <div>
      <div className="section page-banner-noshapes"></div>
      <div className="container">
        <div className="courses-wrapper-02" style={{ width: "100%" }}>
          <div className="row">
            <div style={{ textAlign: "center", padding: "50px 0" }}>
              <h2 style={{ color: "#28a745" }}>
                🎉 Form Submitted Successfully!
              </h2>
              <p>
                Thank you for submitting the form. We will get back to you soon.
              </p>
              <div
                onClick={() => navigate("/")}
                className="btn btn-primary"
                style={{ marginTop: "20px" }}
              >
                Go to Homepage
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSuccess;
